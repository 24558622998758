var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"modal-fade"}},[_c('div',{staticClass:"modal-backdrop"},[(_vm.showBankInfo)?_c('div',{staticClass:"modal"},[_c('header',{staticClass:"modal-header"},[_c('div',{staticClass:"containerHeader bgBlue"},[_c('h1',{staticClass:"titleModal white"},[_vm._v("Effectuer un virement")]),_c('i',{staticClass:"fa-solid fa-xmark fa-xl closeButton",on:{"click":_vm.close}})])]),_c('div',{staticClass:"containerBody"},[(this.bank_details.iban != null)?_c('div',[_c('table',{staticStyle:{"width":"100%"}},[_c('tr',[_c('td',{staticClass:"black"},[_vm._v("Numéro de facture")]),_c('td',{staticClass:"containerBankInfo"},[_c('input',{staticClass:"inputBankInfo black",attrs:{"type":"text","readonly":""},domProps:{"value":_vm.document.number}}),_c('div',{staticClass:"copyButton2",on:{"click":function($event){return _vm.activeButton('number')}}},[_c('img',{attrs:{"src":require("../../assets/icons/copy.svg"),"alt":"copy element"}}),_c('span',{staticClass:"tooltiptext tooltip-left",class:{
                      tooltiptextClicked:
                        _vm.buttons_activated.buttonNumber === true,
                    }},[_vm._v("Copié")])])])]),_c('tr',[_c('td',{staticClass:"black"},[_vm._v("Montant ("+_vm._s(_vm.document.locale_currency)+")")]),_c('td',{staticClass:"containerBankInfo"},[_c('input',{staticClass:"inputBankInfo black",attrs:{"type":"text","readonly":""},domProps:{"value":_vm.formatMoney(_vm.document.amount)}}),_c('div',{staticClass:"copyButton2",on:{"click":function($event){return _vm.activeButton('amount')}}},[_c('img',{attrs:{"src":require("../../assets/icons/copy.svg"),"alt":"copy element"}}),_c('span',{staticClass:"tooltiptext tooltip-left",class:{
                      tooltiptextClicked:
                        _vm.buttons_activated.buttonAmount === true,
                    }},[_vm._v("Copié")])])])]),(_vm.bank_details.name)?_c('tr',[_c('td',{staticClass:"black"},[_vm._v("Nom destinataire")]),_c('td',{staticClass:"containerBankInfo"},[_c('input',{staticClass:"inputBankInfo black",attrs:{"type":"text","readonly":""},domProps:{"value":_vm.bank_details.name}}),_c('div',{staticClass:"copyButton2",on:{"click":function($event){return _vm.activeButton('name')}}},[_c('img',{attrs:{"src":require("../../assets/icons/copy.svg"),"alt":"copy element"}}),_c('span',{staticClass:"tooltiptext tooltip-left",class:{
                      tooltiptextClicked:
                        _vm.buttons_activated.buttonName === true,
                    }},[_vm._v("Copié")])])])]):_vm._e(),_c('tr',[_c('td',{staticClass:"black"},[_vm._v("IBAN")]),_c('td',{staticClass:"containerBankInfo"},[_c('input',{staticClass:"inputBankInfo black",attrs:{"type":"text","readonly":""},domProps:{"value":_vm.bank_details.iban}}),_c('div',{staticClass:"copyButton2",on:{"click":function($event){return _vm.activeButton('iban')}}},[_c('img',{attrs:{"src":require("../../assets/icons/copy.svg"),"alt":"copy element"}}),_c('span',{staticClass:"tooltiptext tooltip-left",class:{
                      tooltiptextClicked:
                        _vm.buttons_activated.buttonIban === true,
                    }},[_vm._v("Copié")])])])]),_c('tr',[_c('td',{staticClass:"black"},[_vm._v("BIC")]),_c('td',{staticClass:"containerBankInfo"},[_c('input',{staticClass:"inputBankInfo black",attrs:{"type":"text","readonly":""},domProps:{"value":_vm.bank_details.bic}}),_c('div',{staticClass:"copyButton2",on:{"click":function($event){return _vm.activeButton('bic')}}},[_c('img',{attrs:{"src":require("../../assets/icons/copy.svg"),"alt":"copy element"}}),_c('span',{staticClass:"tooltiptext tooltip-left",class:{
                      tooltiptextClicked:
                        _vm.buttons_activated.buttonBic === true,
                    }},[_vm._v("Copié")])])])])]),_c('div',{staticClass:"containerInfoSupp"},[_vm._v(" Copiez ces informations dans votre système de virement bancaire pour régler la facture. ")])]):_vm._e(),(_vm.client_id)?_c('div',[(this.bank_details.iban != null)?_c('hr',{staticStyle:{"margin-top":"15px","margin-bottom":"15px"}}):_vm._e(),_c('h2',{staticClass:"titlePaypal"},[_vm._v(" Payer par carte bancaire via le bouton Paypal ")]),_c('div',{staticClass:"button txtButton mauto",staticStyle:{"height":"25px"}},[_c('div',{attrs:{"id":"paypal-container"}})])]):_vm._e()])]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }