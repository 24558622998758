<template>
  <div class="login-container" style="width: 100% !important">
    <div>
      <p class="accept-title">
        Cette facture provient d'une entreprise qui utilise elle-aussi
        {{ name }}.
      </p>
      <p style="margin-bottom: 10px">Vous pouvez:</p>

      <div>
        <div style="display: flex; align-items: center; margin-bottom: 10px">
          <img
            class="accept-icon"
            src="../assets/icons/timer.svg"
            alt=""
            srcset=""
            style="margin-right: 10px"
          />
          <p>Importer cette facture en 1 clic.</p>
        </div>
        <div style="display: flex; align-items: center; margin-bottom: 10px">
          <img
            class="accept-icon"
            src="../assets/icons/organization.svg"
            alt=""
            srcset=""
            style="margin-right: 10px"
          />
          <p>
            Automatiser l'import dans {{ name }} de toutes les prochaines
            factures de ce fournisseur si celui-ci est de confiance.
          </p>
        </div>
      </div>

      <hr class="separator_accept" />

      <div
        style="
          display: flex;
          align-items: center;
          justify-content: left;
          text-align: left;
          cursor: pointer;
        "
      >
        <input
          type="checkbox"
          id="checkbox"
          v-model="checked"
          :disabled="nationalId == null || nationalId.length == 0"
          style="
            margin-right: 10px;
            accent-color: var(--green-600, var(--success));
            cursor: pointer;
          "
        />

        <label for="checkbox" style="cursor: pointer">
          Toujours importer les factures de
          <b>{{ this.document.organization_name }}</b> reçues sur l'email :
          <vs-select class="select-emails" v-model="email_selected" label="">
            <vs-select-item
              :key="index"
              :value="item"
              :text="item"
              v-for="(item, index) in this.emails"
            />
          </vs-select>
          pour l'entreprise <b>{{ this.appName }}</b
          >.
          <!-- <Multiselect v-model="emails_selected" :options="emails" /> -->
        </label>
      </div>

      <p
        v-if="nationalId == null || nationalId.length == 0"
        style="color: #ff4757; margin-top: 30px"
      >
        Votre N°SIRET n'a pas été renseigné par votre fournisseur sur la
        facture. Pour que {{ name }} puisse reconnaître automatiquement votre
        entreprise, demandez à votre fournisseur de l'ajouter sur les prochaines
        factures.
      </p>

      <div
        style="margin: auto; margin-top: 30px !important; width: fit-content"
      >
        <button
          class="btn bgGreen ph20 pv7 radius5 txtButton fw500 ls vs-con-loading__container"
          style="
            box-shadow: 0px 4px 10px 0px rgba(46, 212, 122, 0.16);
            cursor: pointer;
          "
          v-on:click="accept()"
          ref="submit_accept"
        >
          Importer dans mes achats
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createToastInterface } from "vue-toastification";

export default {
  name: "Accept_Connect",
  props: [],
  data() {
    return {
      apps: [],
      app_selected: null,
      colorx: "#000000",
      showLoginError: false,
      loading: false,
      customer: null,
      emails_selected: [],
      email_selected: null,
      checked: false,
      options: {
        timeout: 5000,
        draggable: false,
        closeOnClick: false,
        hideProgressBar: true,
      },
    };
  },
  components: {},
  computed: {
    ...mapState([
      "document",
      "emails",
      "appId",
      "supplierToken",
      "appName",
      "nationalId",
      "name",
    ]),
  },
  async created() {
    this.apps = await this.$store.dispatch("getApps");

    if (this.emails.length > 0) {
      this.email_selected = this.emails[0];
    }
  },
  methods: {
    async accept() {
      this.loading = true;
      this.$vs.loading({
        type: "default",
        color: "white",
        background: "var(--primay)",
        container: this.$refs.submit_accept,
        scale: 0.5,
      });

      let message = "";
      const toast = createToastInterface();

      if (this.checked == true) {
        try {
          await this.$store.dispatch("acceptSupplier", {
            appId: this.appId,
            token: this.supplierToken,
            email: this.email_selected,
          });

          message = "Facture et fournisseur ajouté à votre comptabilité";
        } catch (error) {
          toast.warning("Fournisseur déjà ajouté", this.options);
        }
      } else {
        try {
          await this.$store.dispatch("acceptInvoice", {
            appId: this.appId,
            token: this.supplierToken,
          });

          message = "Facture ajoutée à votre comptabilité";
        } catch (error) {
          toast.warning("Facture déjà ajouté", this.options);
        }
      }

      if (message.length > 0) {
        toast.success(message, this.options);
      }

      this.loading = false;
      this.$vs.loading.close(this.$refs.submit_accept);
      this.$emit("end");
    },
  },
};
</script>

<style lang="scss">
.mb-4 {
  margin-bottom: 14px !important;
}

.select-emails {
  width: fit-content !important;
}

.select-emails > .input-select-con > input {
  border: none !important;
  border-radius: 0px !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2) !important;
}

.separator_accept {
  margin-top: 25px;
  margin-bottom: 20px;
}

label {
  line-height: 2px;
}

label,
p {
  font-size: 12px;
  font-family: "Inter";
}

.login-container {
  margin: auto;
}

.btn-container-2 {
  display: flex;
  justify-content: space-between;
}

.con-select.select-emails {
  width: fit-content !important;
  display: inline-flex;
}

.btn-2 {
  // width: 40% !important;
  width: fit-content !important;
}

.btn {
  background-color: var(--primary);
  padding: 11px 28px;
  border: none;
  border-radius: 7px;
  cursor: pointer;
  transition: background-color 0.4s ease-in-out, box-shadow 0.4s ease-in-out,
    color 0.4s ease-in-out;
  font-size: 14px;
  letter-spacing: 1px;
  color: #fff;
}
.btn:hover {
  background-color: rgba(0, 59, 81, 0.71);
}
.w-full {
  width: 100% !important;
}
.mb-3 {
  margin-bottom: 10.5px !important;
}
.btn-inverse {
  background-color: #fff;
  color: var(--primary);
  border: 1px solid var(--primary);
  height: 38px;
}
.btn-inverse:hover {
  // background-color: rgba(19, 126, 200, 0.11);
  background-color: var(--link-hover, rgba(19, 126, 200, 0.11));
  box-shadow: none;
  color: var(--primary);
}
</style>
