<template>
  <div style="background-color: white">
    <div class="containerBanner">
      <div class="container subContainer">
        <div class="containerLeft">
          <a
            :href="domain == 'sinao.app' ? 'https://sinao.fr' : webflow ?? ''"
            :target="domain == 'sinao.app' || webflow != '' ? '_blank' : ''"
            v-tooltip.right="
              name +
              ' Link est un portail client sécurisé visant <br /> à simplifier vos échanges avec vos fournisseurs <br /> (paiement rapide et comptabilité automatisée).'
            "
          >
            <img
              v-if="this.windowWidth > 700 && whitelabel_logo"
              class="logo small"
              :src="whitelabel_logo"
            />
            <img v-else class="logo" :src="whitelabel_icon" />

            <span v-if="this.windowWidth > 700" class="link pd3Left black">
              Link
            </span>
          </a>

          <span class="black pf10" style="display: none">
            Gérer son activité n'a jamais été aussi simple
          </span>
        </div>

        <div class="containerLeft">
          <img
            src="../assets/icons/lock.svg"
            alt=""
            srcset=""
            style="height: 22px"
          />
          <span class="grey pf10" style="font-size: 12px">Espace sécurisé</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import logoBlack from "../assets/logo-black.svg";
import icon from "../assets/icon.png";

export default {
  name: "Banner_sinao",
  props: {},
  data() {
    return {
      windowWidth: window.innerWidth,
      whitelabel_logo: null,
      whitelabel_icon: null,
    };
  },
  computed: {
    ...mapState(["logo", "domain", "name", "webflow"]),
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  created() {},
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },
  },
  watch: {
    domain: {
      handler(newVal) {
        console.log(newVal);

        this.whitelabel_logo = newVal === "sinao.app" ? logoBlack : this.logo;
        this.whitelabel_icon = newVal === "sinao.app" ? icon : this.logo;
      },
      immediate: true,
    },
  },
};
</script>

<style>
.container {
  width: 100%;
  margin: auto;
  padding-left: 25%;
  padding-right: 25%;
}

@media screen and (max-width: 1800px) {
  .container {
    width: 100%;
    margin: auto;
    padding-left: 15%;
    padding-right: 15%;
  }
}

.containerBanner {
  width: calc(100% - 200px);
  height: 40px;
  background-color: #ffffff;
}

.subContainer {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.containerLeft {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.logo {
  height: 20px;
  width: auto;
}

.small {
  height: 16px;
}

i {
  width: 14px;
  height: 14px;
}
.link {
  font-size: 12px;
  font-weight: normal;
  font-family: "Inter";
  text-decoration: none;
}

@media screen and (max-width: 630px) {
  .containerBanner {
    width: calc(100% - 115px);
  }

  .container {
    padding-left: 5%;
    padding-right: 5%;
  }
}
</style>
