/* eslint-disable camelcase */
const state = {
  user: null,
  name: null,
  logo: null,
  step_connect: 1,
  appId: null,
  appName: null,
  nationalId: null,
  document: null,
  emails: null,
  bearer: "",
  documents: [],
  selected_document_id: null,
  selected_document: null,
  domain: null,
  account_manager: "https://account.sinao.app",
  webflow: "https://sinao.fr",
};

export default state;
