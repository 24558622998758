<template>
  <div id="app">
    <template v-if="!this.loading">
      <Banner />
      <div class="containerBodyApp">
        <Export_document :response="response" />
        <Side_bar />
      </div>
    </template>

    <div
      v-else
      style="
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
      "
    >
      <div class="loader"></div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { query } from "sinao-corejs";

import Banner from "./components/Banner.vue";
import Side_bar from "./components/Side_bar.vue";
import Export_document from "./views/Export_document.vue";

export default {
  name: "App",
  components: {
    Banner,
    Export_document,
    Side_bar,
  },
  data() {
    return {
      loading: true,
      url: "https://api.sinao.app/v1",
      token: "",
      response: {},
    };
  },
  computed: {
    ...mapState(["domain", "webflow", "name"]),
  },
  async created() {
    this.loading = true;

    this.url =
      window.location.hostname === "localhost"
        ? "https://api.sinao.test/v1"
        : "https://api.sinao.app/v1";

    const urlParams = new URLSearchParams(window.location.search);
    const supplierToken = urlParams.get("token");
    console.log("supplierToken APP", supplierToken);
    if (supplierToken?.length > 0) {
      await this.$store.dispatch("setSupplierToken", { token: supplierToken });
      this.token = supplierToken;
    } else {
      window.location.href = this.webflow ?? "https://sinao.fr";
    }

    await query({
      operationId: "app.documents.sales.exportinvoice.token_validation",
      params: {
        token: this.token,
      },
    })
      .then(async (result) => {
        await this.$store.dispatch("branding", {
          hostname_alias: result.hostname_alias ?? "sinao.app",
        });

        this.response = result;
        this.loading = false;
      })
      .catch((e) => {
        console.error(e);
        window.location.href = this.webflow ?? "https://sinao.fr";
      });
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Jost:ital,wght@0,100..900;1,100..900&display=swap");

@import "./assets/css/main.css";
@import "./assets/css/modal.css";
@import "./assets/css/tooltip.css";
@import "./assets/css/variables.scss";

body,
#app {
  background-color: #ffffff;
  width: 100%;
  margin: 0px;
  font-family: "Inter";
}

/* GLOBAL CCS */
.white {
  color: #ffffff !important;
}
.bgWhite {
  background-color: rgb(253, 253, 253, 0.98);
}

.green {
  // color: #369e3b !important;
  color: var(--green-600, var(--success)) !important;
}
.bgGreen {
  // background-color: rgb(54, 158, 59, 0.98);
  background-color: var(--green-600, var(--success)) !important;
}

.greyLight {
  color: #6c6c6c !important;
}
.bgLightGrey {
  background-color: #003b5175;
}

.grey {
  color: #6b7280 !important;
}
.bgGrey {
  background-color: #ebeff2;
}
.bgDarkGrey {
  background-color: #a1a0a0;
}

.black {
  // color: #000000 !important;
  color: var(--black) !important;
}
.blue {
  // color: #003b51 !important;
  color: var(--primary) !important;
}
.bgBlue {
  // background-color: #003b51;
  background-color: var(--primary) !important;
}

.bgOrange {
  // background-color: #f08500;
  background-color: var(--warning) !important;
}

.mauto {
  margin: auto;
}

.mt20 {
  margin-top: 20px;
}

.mt30 {
  margin-top: 30px;
}

.mb40 {
  margin-bottom: 40px;
}

.mt40 {
  margin-top: 40px;
}

.mr10 {
  margin-right: 10px;
}

.pd5 {
  padding: 5px;
}

.pd710 {
  padding: 7px 10px;
}
.pf10 {
  padding-left: 10px;
}

.pd20H {
  padding: 0px 20px;
}

.pd10Top {
  padding-top: 10px;
}

.pd3Left {
  padding-left: 3px;
}

.radius5 {
  border-radius: 5px;
}

.w175 {
  width: 176px;
}

.h30 {
  height: 30px;
}

span {
  font-size: 13px;
  line-height: 16px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.01em;
}

.fw600 {
  font-weight: 600;
}

.fw500 {
  font-weight: 500;
}

.fw300 {
  font-weight: 300;
}

.redirectButton {
  text-decoration: none;
}

html {
  overflow: hidden;
  height: 100%;
}

body {
  overflow: auto;
  height: 100%;
}

.containerBodyApp {
  display: flex;
}
</style>
