<template>
  <div class="login-container" style="width: 100% !important">
    <div class="title-container">
      <h4 class="mb-4">Bonjour {{ this.user.civil_name }}</h4>
    </div>

    <div class="input-container">
      <div v-if="this.apps.length > 0">
        <vs-select
          :color="colorx"
          class="selectExample"
          v-model="app_id_selected"
          :label="
            'Sélectionnez le compte ' +
            name +
            ' dans lequel importer cette facture :'
          "
        >
          <vs-select-item
            :key="index"
            :value="item.id"
            :text="item.organization.name"
            v-for="(item, index) in this.apps"
          />
        </vs-select>
      </div>

      <p
        v-if="this.apps != null && this.apps.length == 0"
        style="color: #ff4757; text-align: center"
      >
        Aucune entreprise trouvée.
      </p>
    </div>

    <div class="btn-container" v-if="this.apps.length > 0">
      <button
        ref="submit_app"
        class="w-full btn vs-con-loading__container"
        :disabled="loading"
        @click="save"
      >
        Selectionner
      </button>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Select_app",
  props: [],
  data() {
    return {
      apps: null,
      app_id_selected: null,
      app_name_selected: null,
      colorx: "#000000",
      showLoginError: false,
      loading: false,
      customer: null,
    };
  },
  computed: {
    ...mapState(["step_connect", "user", "name"]),
  },
  async created() {
    this.apps = await this.$store.dispatch("getApps");

    if (this.apps.length == 1) {
      this.app_id_selected = this.apps[0].id;
      this.app_name_selected = this.apps[0].organization.name;
      this.save();
    }
  },
  methods: {
    save() {
      if (this.app_id_selected) {
        this.apps.find((app) => {
          if (app.id == this.app_id_selected) {
            this.app_name_selected = app.organization.name;
          }
        });

        if (this.app_name_selected != null) {
          this.$store.dispatch("setApp", {
            appId: this.app_id_selected,
          });

          this.$store.dispatch("setAppName", {
            appName: this.app_name_selected,
          });

          this.$store.dispatch("changeStep", { step: 3 });
        }
      }
    },
  },
};
</script>

<style lang="scss">
.mb-4 {
  margin-bottom: 14px !important;
}

.login-container {
  margin: auto;
}
.con-select {
  width: 100% !important;
}

.btn {
  background-color: var(--primary);
  padding: 11px 28px;
  border: none;
  border-radius: 7px;
  cursor: pointer;
  transition: background-color 0.4s ease-in-out, box-shadow 0.4s ease-in-out,
    color 0.4s ease-in-out;
  font-size: 14px;
  letter-spacing: 1px;
  color: #fff;
}
.btn:hover {
  background-color: rgba(0, 59, 81, 0.71);
}
.w-full {
  width: 100% !important;
}
.mb-3 {
  margin-bottom: 10.5px !important;
}
.btn-inverse {
  background-color: #fff;
  color: var(--primary);
  border: 1px solid var(--primary);
  height: 38px;
}
.btn-inverse:hover {
  background-color: rgba(19, 126, 200, 0.11);
  box-shadow: none;
  color: var(--primary);
}
</style>
