import { query } from "sinao-corejs";

const actions = {
  async branding({ commit }, payload) {
    var { hostname_alias } = payload;
    if (!hostname_alias || hostname_alias.trim() === "") {
      hostname_alias = "sinao.app";
    }

    const brand = await fetch(
      `https://api.sinao.app/v1/branding.json?domain=${hostname_alias}&cacheBuster=${new Date().getTime()}`
    ).then((response) => response.json());

    const { icon, logo, name, domain, account_manager, webflow, colors } =
      brand;

    commit("SET_NAME", name);
    commit("SET_DOMAIN", domain);
    commit("SET_ACCOUNT_MANAGER", account_manager);
    commit("SET_WEBFLOW", webflow);

    if (domain !== "sinao.app") {
      commit("SET_LOGO", logo);

      const favicon = document.querySelector("link[rel='icon']");
      favicon.href = icon;
    }

    if (colors) {
      const root = document.documentElement;
      for (const [key, value] of Object.entries(colors)) {
        root.style.setProperty(`--${key}`, value);
      }
    }

    return brand;
  },
  async getUser({ commit }) {
    const response = await query(
      {
        operationId: "account.get",
      },
      true
    );

    commit("SET_USER", response);
  },
  async login(_, payload) {
    const { email, password } = payload;

    const response = await query({
      operationId: "auth.login",
      params: {
        email,
        password,
      },
    });

    document.cookie = `token=${response.access_token}; path=/;`;
    console.log(response.access_token);
    console.log(document.cookie);

    return response;
  },
  async getApps() {
    const { data } = await query({
      operationId: "app.list",
      params: {
        "expand[0]": "organization",
        "expand[1]": "policies",
        "expand[2]": "policies.user",
        "expand[3]": "policies.profile",
        "expand[4]": "subscription",
        "expand[5]": "invites",
        "expand[6]": "organization.headquarters",
        "expand[7]": "admin",
        limit: "1000",
      },
    });

    return data;
  },
  changeStep({ commit }, payload) {
    const { step } = payload;

    commit("CHANGE_STEP", step);
  },
  setApp({ commit }, payload) {
    const { appId } = payload;

    commit("SET_APP", appId);
  },
  setAppName({ commit }, payload) {
    const { appName } = payload;

    commit("SET_APP_NAME", appName);
  },
  setDocument({ commit }, payload) {
    const { document } = payload;

    commit("SET_DOCUMENT", document);
  },
  setEmails({ commit }, payload) {
    const { emails } = payload;

    commit("SET_EMAILS", emails);
  },
  setNationalId({ commit }, payload) {
    const { nationalId } = payload;

    commit("SET_NATIONAL_ID", nationalId);
  },
  setDocuments({ commit }, payload) {
    const { documents } = payload;

    commit("SET_DOCUMENTS", documents);
  },
  setSelectedDocumentId({ commit }, payload) {
    const { selected_document_id } = payload;

    commit("SET_SELECTED_DOCUMENT_ID", selected_document_id);
  },
  setSelectedDocument({ commit }, payload) {
    const { selected_document } = payload;

    commit("SET_SELECTED_DOCUMENT", selected_document);
  },
  setSupplierToken({ commit }, payload) {
    const { token } = payload;

    commit("SET_SUPPLIER_TOKEN", token);
  },
  setBearer({ commit }, payload) {
    const { bearer } = payload;

    commit("SET_BEARER", bearer);
  },
  async acceptSupplier(_, payload) {
    const { appId, token, email } = payload;

    await query({
      operationId: "app.supplierPurchase.accepted",
      params: {
        appId: appId,
        token: token,
        email: email,
      },
    });
  },
  async acceptInvoice(_, payload) {
    const { appId, token } = payload;

    await query({
      operationId: "app.supplierPurchase.accepted_invoice",
      params: {
        appId: appId,
        token: token,
      },
    });
  },
  async downloadInvoice(_, payload) {
    const { appId, invoiceId } = payload;

    await query({
      operationId: "app.documents.sales.invoices.download",
      params: {
        appId: appId,
        ids: [invoiceId],
      },
    });
  },
};

export default actions;
